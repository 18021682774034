import { makeStyles } from '@material-ui/core/styles';
import appleImage from '../../images/app-store-badge.svg';

const useStyles = makeStyles({
    badge: {
        display: 'inline-block',
        width: 135,
        height: 40,
        cursor: 'pointer',
    },
});

const iosLink = 'https://apps.apple.com/us/app/voluntime-hour-logs/id1567853199';

const AppStoreBadge = () => {
    const classes = useStyles();

    return (
        <a href={iosLink} target='_blank' rel='noopener noreferrer' className={classes.badge}>
            <img src={appleImage} alt='App Store' />
        </a>
    );
};

export default AppStoreBadge;